<template>
    <div class="grid grid-cols-1 mt-12 max-w-lg">
        <ValidationErrors/>

        <div v-if="status">
            {{ status }}
        </div>

        <GoogleSignInButton />

        <p class="mt-4">
            {{ $t('auth.google_login.description') }}
        </p>

        <p class="text-xs" v-if="config.featureFlags.login.show_email_login_link">
            {{ $t('auth.google_login.or') }}
            <Link :href="route('login-auth')" class="link">
                {{ $t('auth.google_login.login_here') }}
            </Link>
        </p>

        <p class="mt-12">
            {{ $t('auth.google_login.not_on_css') }}
            <a
                href="https://producthero.com/google-css"
                target="_blank"
                class="inline-block md:inline-flex text-blue-700 font-bold"
            >
                {{ $t('auth.google_login.signup_css') }}
            </a>
        </p>
    </div>
</template>
<script setup lang="ts">
    import config from '@/config';
    import GoogleSignInButton from '@/Components/GoogleSignInButton.vue';
    import ValidationErrors from '@/Components/ValidationErrors.vue';

    defineProps({
        status: String,
        env: String,
    });
</script>
