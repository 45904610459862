<template>
    <section class="grid grid-cols-1 md:grid-cols-2 h-screen bg-white w-screen place-items-center">
        <div class="place-self-center h-96 w-screen ">
            <div class="grid place-self-center max-w-lg mx-auto px-4">
                <ApplicationLogo :dark="true" class="max-w-max !text-gray-700 -mt-20 mb-20" :icon-only="false"/>

                <Heading size="h2">{{ $t('auth.google_login.title') }}</Heading>

                <slot/>
            </div>

        </div>

        <div class="hidden md:block w-full h-screen bg-gray-100">
            <div class="m-auto flex h-screen justify-center items-center">
                <img class="w-3/5" :src="usePage().props.urls.media + '/producthero-optimizer-banner.svg'"
                     alt="ProductHero"/>
            </div>
        </div>
    </section>
</template>
<script setup lang="ts">
    import ApplicationLogo from '@/Components/ApplicationLogo.vue';
    import { usePage } from '@inertiajs/vue3';
    import Heading from '@/Components/Heading.vue';
</script>
