<template>
    <h1 v-if="size === 'h1'" :class="cssClass ? cssClass : 'text-3xl font-bold font-nunito text-gray-700'">
        <slot />
    </h1>

    <h2
        v-if="size === 'h2'"
        :class="cssClass ? cssClass : 'text-2xl font-semibold min-w-fit font-nunito text-gray-700 leading-[2rem]'">
        <slot />
    </h2>

    <h3 v-if="size === 'h3'" :class="cssClass ? cssClass : 'text-xl font-semibold font-nunito text-gray-700 leading-[1.7rem]'">
        <slot />
    </h3>

    <h4 v-if="size === 'h4'" :class="cssClass ? cssClass : 'text-lg font-semibold font-nunito text-gray-700'">
        <slot />
    </h4>

    <h5 v-if="size === 'h5'" :class="cssClass ? cssClass : 'text-base font-semibold font-nunito text-gray-700'">
        <slot />
    </h5>

    <h6 v-if="size === 'h6'" :class="cssClass ? cssClass : 'text-base font-nunito text-gray-700'">
        <slot />
    </h6>
</template>

<script setup>
    defineProps(['size', 'cssClass']);
</script>
